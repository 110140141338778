import http from "../../constants/token-interceptor";
import {
  resetPasswordURL,
  logOutURL,
  logInURL,
  signUpURL,
  forgotPasswordURL,
  changePasswordURL,
  getUserProfileURL,
  emulateUserLogInURL,
  emulateUserAuthorizationURL,
  getTargetLocationURL,
} from "../../constants/microservices-helper";
import ERRORS_CONST from "../../constants/errors";
// constants
const LOG_IN_START = "sponsorship/authentication/LOG_IN_START";
const LOG_IN_SUCCESS = "sponsorship/authentication/LOG_IN_SUCCESS";
const LOG_IN_FAILURE = "sponsorship/authentication/LOG_IN_FAILURE";
const LOG_OUT_START = "sponsorship/authentication/LOG_OUT_START";
const LOG_OUT_SUCCESS = "sponsorship/authentication/LOG_OUT_SUCCESS";
const LOG_OUT_FAILURE = "sponsorship/authentication/LOG_OUT_FAILURE";
const SIGN_UP_START = "sponsorship/authentication/SIGN_UP_START";
const SIGN_UP_SUCCESS = "sponsorship/authentication/SIGN_UP_SUCCESS";
const SIGN_UP_FAILURE = "sponsorship/authentication/SIGN_UP_FAILURE";

const FORGOT_PASSWORD_START =
  "sponsorship/authentication/FORGOT_PASSWORD_START";
const FORGOT_PASSWORD_SUCCESS =
  "sponsorship/authentication/FORGOT_PASSWORD_SUCCESS";
const FORGOT_PASSWORD_FAILURE =
  "sponsorship/authentication/FORGOT_PASSWORD_FAILURE";

const CHANGE_PASSWORD_START =
  "sponsorship/authentication/CHANGE_PASSWORD_START";
const CHANGE_PASSWORD_SUCCESS =
  "sponsorship/authentication/CHANGE_PASSWORD_SUCCESS";
const CHANGE_PASSWORD_FAILURE =
  "sponsorship/authentication/CHANGE_PASSWORD_FAILURE";

const RESET_PASSWORD_START = "sponsorship/authentication/RESET_PASSWORD_START";
const RESET_PASSWORD_SUCCESS =
  "sponsorship/authentication/RESET_PASSWORD_SUCCESS";
const RESET_PASSWORD_FAILURE =
  "sponsorship/authentication/RESET_PASSWORD_FAILURE";

const CLEAR_ERRORS = "sponsorship/authentication/CLEAR_ERRORS";

const USER_PROFILE_START = "sponsorship/authentication/USER_PROFILE_START";
const USER_PROFILE_SUCCESS = "sponsorship/authentication/USER_PROFILE_SUCCESS";
const USER_PROFILE_FAILURE = "sponsorship/authentication/USER_PROFILE_FAILURE";

const EMULATE_USER_START = "sponsorship/authentication/EMULATE_USER_START";
const EMULATE_USER_SUCCESS = "sponsorship/authentication/EMULATE_USER_SUCCESS";
const EMULATE_USER_FAILURE = "sponsorship/authentication/EMULATE_USER_FAILURE";

const AUTHORIZED_EMULATE_USER_START =
  "sponsorship/authentication/AUTHORIZED__USER_START";
const AUTHORIZED_EMULATE_USER_SUCCESS =
  "sponsorship/authentication/AUTHORIZED__USER_SUCCESS";
const AUTHORIZED_EMULATE_USER_FAILURE =
  "sponsorship/authentication/AUTHORIZED__USER_FAILURE";
export const FETCH_TARGETLOCATION_START =
  "sponsorship/proposals/FETCH_TARGETLOCATION_START";
export const FETCH_TARGETLOCATION_SUCCESS =
  "sponsorship/proposals/FETCH_TARGETLOCATION_SUCCESS";
export const FETCH_TARGETLOCATION_FAILURE =
  "sponsorship/proposals/FETCH_TARGETLOCATION_FAILURE";
export const FETCH_TARGETLOCATION_RESET =
  "sponsorship/proposals/FETCH_TARGETLOCATION_RESET";
// initialState
const initialState = {
  userName: null,
  id: null,
  isAuthenticated: sessionStorage.getItem("access_token") ? true : false,
  isFetching: false,
};

// reducer
export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case LOG_IN_START:
    case LOG_IN_SUCCESS:
    case LOG_IN_FAILURE:
      return { ...state, ...payload };
    case LOG_OUT_START:
    case LOG_OUT_FAILURE:
      return { ...state, ...payload };
    case LOG_OUT_SUCCESS:
      return {
        userName: null,
        id: null,
        isAuthenticated: false,
        isFetching: false,
      };
    case SIGN_UP_START:
    case SIGN_UP_FAILURE:
      return { ...state, ...payload };
    case SIGN_UP_SUCCESS:
      const { isNewUser } = payload;
      return { ...state, isNewUser };
    case FORGOT_PASSWORD_START:
    case FORGOT_PASSWORD_SUCCESS:
    case FORGOT_PASSWORD_FAILURE:
      return { ...state, ...payload };
    case CHANGE_PASSWORD_START:
    case CHANGE_PASSWORD_SUCCESS:
    case CHANGE_PASSWORD_FAILURE:
      return { ...state, ...payload };
    case FETCH_TARGETLOCATION_START:
    case FETCH_TARGETLOCATION_SUCCESS:
    case FETCH_TARGETLOCATION_FAILURE:
    case FETCH_TARGETLOCATION_RESET:
      return { ...state, ...payload };

    case RESET_PASSWORD_START:
    case RESET_PASSWORD_SUCCESS:
    case RESET_PASSWORD_FAILURE:
      return { ...state, ...payload };
    case CLEAR_ERRORS:
      const { error, ...rest } = state;
      return rest;
    case USER_PROFILE_START:
    case USER_PROFILE_FAILURE:
      return { ...state, ...payload };
    case USER_PROFILE_SUCCESS:
      const currentUserEmail = payload.userProfile.data.EmailAddress;

      return { ...state, ...payload, currentUserEmail };
    case AUTHORIZED_EMULATE_USER_START:
    case AUTHORIZED_EMULATE_USER_FAILURE:
      return { ...state, ...payload };
    case EMULATE_USER_FAILURE:
      return { ...state, ...payload };
    case AUTHORIZED_EMULATE_USER_SUCCESS:
      const { clientKey, isEmulateAuthorized, isLoading } = payload;

      return { ...state, clientKey, isEmulateAuthorized, isLoading };
    default:
      return state;
  }
}

export const sessionLogOut = () => {
  return () => {
    const url = logOutURL();
    http
      .get(url)
      .then(() => {
        sessionStorage.clear();
      })
      .catch(() => {
        sessionStorage.clear();
      });
  };
};

export const logOut = (user) => {
  return (dispatch) => {
    dispatch({
      type: LOG_OUT_START,
      payload: {
        id: "somethingUnique",
        data: "bunches of it",
        isAuthenticated: false,
        isFetching: true,
      },
    });
    const url = logOutURL();
    http
      .get(url)
      .then((response) => {
        sessionStorage.clear();
        dispatch({
          type: LOG_OUT_SUCCESS,
          payload: {},
        });
      })
      .catch((error) => {
        sessionStorage.clear();
        dispatch({
          type: LOG_OUT_SUCCESS,
          payload: {},
        });
      });
  };
};

export const clearError = () => ({
  type: CLEAR_ERRORS,
  payload: {},
});

export const logIn = (user, query) => {
  const querystring = require("querystring");
  const requestBody = {
    UserName: user.email,
    Password: user.password,
    grant_type: "password",
  };
  sessionStorage.setItem("email", user.email);
  return (dispatch) => {
    dispatch({
      type: LOG_IN_START,
      payload: {
        isAuthenticated: false,
        isFetching: true,
        isButtonDisable: true,
        type: "Login",
      },
    });
    const url = logInURL();
    http
      .post(url, querystring.stringify(requestBody))
      .then((response) => {
        const data = response.data;
        if (data) {
          sessionStorage.setItem("access_token", data.access_token);

          if (query) {
            dispatch(targetLocation(query));
          } else {
            dispatch({
              type: LOG_IN_SUCCESS,
              payload: {
                isAuthenticated: true,
                isFetching: false,
                type: "Login",
              },
            });
          }
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.status === 400 &&
          error.response.data &&
          error.response.data.error === "Force_Password_Reset"
        ) {
          dispatch({
            type: LOG_IN_FAILURE,
            payload: {
              needPasswordChange: true,
              data: error,
              isAuthenticated: false,
              isFetching: false,
              type: "Login",
            },
          });
        } else {
          dispatch({
            type: LOG_IN_FAILURE,
            payload: {
              error:
                "Either the username or password entered was incorrect, or you have locked out your account. If you have forgotten your password, please click on Forgot Password and a link to reset your account will be sent to the email on record.",
              data: error,
              isAuthenticated: false,
              isFetching: false,
              isButtonDisable: false,
              type: "Login",
            },
          });
        }
      });
  };
};

// export const resetTargetLocation = () => ({
// 	type: FETCH_TARGETLOCATION_RESET,
// 	payload: {}
//   });

export const resetTargetLocation = () => (dispatch) => {
  dispatch({
    type: FETCH_TARGETLOCATION_RESET,
    payload: {
      targetLocationData: null,
      isFetching: false,
      // fetchSuccess: true,
      isLoading: false,
    },
  });
};

export const targetLocation = (query) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_TARGETLOCATION_START,
      isFetching: true,
      isLoading: false,
    });
    const url = getTargetLocationURL();
    http
      .post(url, { payload: query })
      .then((response) => {
        dispatch({
          type: FETCH_TARGETLOCATION_SUCCESS,
          payload: {
            targetLocationData: response.data,
            isFetching: false,
            // fetchSuccess: true,
            isLoading: true,
          },
        });
        dispatch({
          type: LOG_IN_SUCCESS,
          payload: {
            isAuthenticated: true,
            isFetching: false,
            type: "Login",
          },
        });
      })
      .catch((error) => {
        if (error && error.response && error.response.data) {
          dispatch({
            type: FETCH_TARGETLOCATION_FAILURE,
            payload: {
              error: ERRORS_CONST.ERROR_TARGETLOCATION_PROPOSALS,
              ...error,
              targetLocationData: error.response.data,
              isFetching: false,
              isLoading: true,
            },
          });
          dispatch({
            type: LOG_IN_SUCCESS,
            payload: {
              isAuthenticated: true,
              isFetching: false,
              type: "Login",
            },
          });
        } else {
          dispatch({
            type: FETCH_TARGETLOCATION_FAILURE,
            payload: {
              error: ERRORS_CONST.ERROR_TARGETLOCATION_PROPOSALS,
              ...error,
              targetLocationData: "Could not find target locatin",
              isFetching: false,
              isLoading: true,
            },
          });
          dispatch({
            type: LOG_IN_SUCCESS,
            payload: {
              isAuthenticated: true,
              isFetching: false,
              type: "Login",
            },
          });
        }
      });
  };
};

export const signUp = (user, query) => {
  const requestBody = {
    EmailAddress: user.email,
    Password: user.password,
  };
  return (dispatch) => {
    dispatch({
      type: SIGN_UP_START,
      payload: { isAuthenticated: false, isFetching: true, type: "Signup" },
    });
    const url = signUpURL();
    http
      .post(url, requestBody)
      .then((response) => {
        dispatch({
          type: SIGN_UP_SUCCESS,
          payload: {
            isNewUser: true,
            isFetching: false,
          },
          isFetching: false,
        });
        // if(query) {
        // 	dispatch(targetLocation(query))
        // }
        dispatch(logIn(user, query));
      })
      .catch((error) => {
        if (error && error.response && error.response.data.Status === 4) {
          dispatch({
            type: SIGN_UP_FAILURE,
            payload: {
              error: "ALREADY-EXISTS",
              ...error.response,
              isAuthenticated: false,
              isFetching: false,
              type: "Signup",
            },
          });
        } else {
          dispatch({
            type: LOG_IN_FAILURE,
            payload: {
              error: "Record does not exist",
              ...error.response,
              isAuthenticated: false,
              isFetching: false,
              type: "Signup",
            },
          });
        }
      });
  };
};

export const forgotPassword = (user) => {
  const requestBody = {
    EmailAddress: user.email,
  };
  return (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD_START,
      payload: {
        isAuthenticated: false,
        isFetching: true,
        type: "forgotpassword",
      },
    });
    const url = forgotPasswordURL();
    http
      .post(url, requestBody)
      .then((response) => {
        dispatch({
          type: FORGOT_PASSWORD_SUCCESS,
          payload: {
            isAuthenticated: false,
            isFetching: false,
            type: "forgotpassword",
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: FORGOT_PASSWORD_FAILURE,
          payload: {
            error: "",
            isAuthenticated: false,
            isFetching: false,
            type: "forgotpassword",
          },
        });
      });
  };
};

export const changePassword = (user, email) => {
  const requestBody = {
    OldPassword: user.oldPassword,
    EmailAddress: email,
    NewPassword: user.password,
  };
  return (dispatch) => {
    dispatch({
      type: CHANGE_PASSWORD_START,
      payload: {
        isAuthenticated: false,
        isFetching: true,
        isUserChangePassword: false,
        type: "changePassword",
        isButtonDisable: false,
      },
    });
    const url = changePasswordURL();
    http
      .post(url, requestBody)
      .then((response) => {
        dispatch({
          type: CHANGE_PASSWORD_SUCCESS,
          payload: {
            isAuthenticated: false,
            isFetching: false,
            isUserChangePassword: true,
            type: "changePassword",
            needPasswordChange: false,
            isButtonDisable: false,
          },
        });
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.Status === 0
        ) {
          dispatch({
            type: CHANGE_PASSWORD_FAILURE,
            payload: {
              error: "Email Address or Old password is not matched.",
              isAuthenticated: false,
              isFetching: false,
              isUserChangePassword: false,
              type: "changePassword",
              needPasswordChange: false,
              isButtonDisable: false,
            },
          });
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.Status === 9
        ) {
          dispatch({
            type: CHANGE_PASSWORD_FAILURE,
            payload: {
              error:
                "This password is already used, please try a different one.",
              isAuthenticated: false,
              isFetching: false,
              isUserChangePassword: false,
              type: "changePassword",
              needPasswordChange: false,
              isButtonDisable: false,
            },
          });
        } else {
          dispatch({
            type: CHANGE_PASSWORD_FAILURE,
            payload: {
              error: "",
              isAuthenticated: false,
              isFetching: false,
              isUserChangePassword: false,
              type: "changePassword",
              needPasswordChange: false,
              isButtonDisable: false,
            },
          });
        }
      });
  };
};

export const getUserProfile = () => {
  return (dispatch) => {
    dispatch({
      type: USER_PROFILE_START,
      payload: { isAuthenticated: true, isFetching: true },
    });
    const url = getUserProfileURL();
    http
      .get(url)
      .then((response) => {
        dispatch({
          type: USER_PROFILE_SUCCESS,
          payload: {
            userProfile: response,
            isAuthenticated: true,
            isFetching: false,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: USER_PROFILE_FAILURE,
          payload: { error: "", isAuthenticated: false, isFetching: false },
        });
      });
  };
};

export const resetPassword = (user, referenceId) => {
  const requestBody = {
    Password: user.password,
    ReferenceID: referenceId,
  };
  return (dispatch) => {
    dispatch({
      type: RESET_PASSWORD_START,
      payload: {
        isAuthenticated: false,
        isFetching: true,
        isUserChangePassword: false,
        type: "resetPassword",
      },
    });
    const url = resetPasswordURL();
    http
      .post(url, requestBody)
      .then((response) => {
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
          payload: {
            isAuthenticated: false,
            isFetching: false,
            isUserChangePassword: true,
            type: "resetPassword",
          },
        });
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.Status === 0
        ) {
          dispatch({
            type: RESET_PASSWORD_FAILURE,
            payload: {
              error: "Reference id does not exist",
              isAuthenticated: false,
              isFetching: false,
              isUserChangePassword: false,
              type: "resetPassword",
            },
          });
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.Status === 2
        ) {
          dispatch({
            type: RESET_PASSWORD_FAILURE,
            payload: {
              error: "Reference id is already used.",
              isAuthenticated: false,
              isFetching: false,
              isUserChangePassword: false,
              type: "resetPassword",
            },
          });
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.Status === 3
        ) {
          dispatch({
            type: RESET_PASSWORD_FAILURE,
            payload: {
              error: "Reference id is expired.",
              isAuthenticated: false,
              isFetching: false,
              isUserChangePassword: false,
              type: "resetPassword",
            },
          });
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.Status === 9
        ) {
          dispatch({
            type: RESET_PASSWORD_FAILURE,
            payload: {
              error:
                "This password is already used, please try a different one.",
              isAuthenticated: false,
              isFetching: false,
              isUserChangePassword: false,
              type: "resetPassword",
            },
          });
        } else {
          dispatch({
            type: RESET_PASSWORD_FAILURE,
            payload: {
              error: "",
              isAuthenticated: false,
              isFetching: false,
              isUserChangePassword: false,
              type: "resetPassword",
            },
          });
        }
      });
  };
};

export const emulateUserLogIn = (user, authcode, clientKey) => {
  const querystring = require("querystring");
  const requestBody = {
    UserName: user.email,
    grant_type: "password",
    authcode: authcode,
    clientKey: clientKey,
  };
  return (dispatch) => {
    dispatch({
      type: EMULATE_USER_START,
      payload: {
        isAuthenticated: false,
        isFetching: true,
      },
    });
    const url = emulateUserLogInURL();
    http
      .post(url, querystring.stringify(requestBody))
      .then((response) => {
        const data = response.data;
        sessionStorage.setItem("access_token", data.access_token);
        dispatch({
          type: EMULATE_USER_SUCCESS,
          payload: {
            isAuthenticated: true,
            isFetching: false,
          },
        });
        dispatch(getUserProfile());
      })
      .catch((error) => {
        dispatch({
          type: EMULATE_USER_FAILURE,
          payload: {
            error: "Record does not exist.",
            isAuthenticated: false,
            isFetching: false,
            isUserChangePassword: false,
          },
        });
      });
  };
};

export const emulateUserAuthorization = (authCode) => {
  const querystring = require("querystring");
  const requestBody = {
    grant_type: "password",
    authcode: authCode,
  };
  return (dispatch) => {
    dispatch({
      type: AUTHORIZED_EMULATE_USER_START,
      payload: {
        isEmulateAuthorized: false,
        isFetching: true,
        isLoading: false,
      },
    });
    const url = emulateUserAuthorizationURL();
    http
      .post(url, querystring.stringify(requestBody))
      .then((response) => {
        dispatch({
          type: AUTHORIZED_EMULATE_USER_SUCCESS,
          payload: {
            clientKey: response.data.clientKey,
            isEmulateAuthorized: true,
            isFetching: false,
            isLoading: true,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: AUTHORIZED_EMULATE_USER_FAILURE,
          payload: {
            error: "You are not authorized to access this page",
            isEmulateAuthorized: false,
            isFetching: false,
            isLoading: true,
          },
        });
      });
  };
};
